@import url('https://fonts.googleapis.com/css2?family=Arvo:wght@400;700&family=Poppins:wght@400;500;600;700&display=swap');

:root {
    --gold: #DAA520;
    --black: #161618;
    --white: #FFFFFF;
    --gray: #414143;
}

body {
    height: 100vh;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--black);
}

#root {
    height: 100%;
}

a {
    text-decoration: none;
}

.App {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

h1 {
    color: var(--gold);
    font-family: inherit;
    font-weight: 500;
}

@media only screen and (max-width: 767px) {
    h1 {
        font-size: 21px;
    }
}