.Services {
    width: 75%;
    margin: 0px auto 25px;
}

.Services-header {
    height: 550px;
    margin-bottom: 50px;
}

.Services-list {
    display: grid;
    flex-wrap: wrap;
    height: 500px;
    width: 100%;
    gap: 50px;
    grid-template-columns: 1fr 1fr 1fr;
}

.Services-list-item {
    width: 100%;
    box-sizing: border-box;
}

@media only screen and (max-width: 767px) {
    .Services {
        width: 85%;
    }

    .Services-additional-header {
        display: none;
    }

    .Services-list {
        width: 100%;
        height: unset;
        gap: 25px;
        grid-template-rows: repeat(7, 1fr);
        grid-template-columns: repeat(1, 1fr);
    }

    .Services-list-item {
        height: 150px;
    }
}

.Services-list-image-landscape img {
    background-color: #1A1A1A;
    object-fit: contain !important;
}

/*
    ServiceLink
*/

.ServiceLink {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border: 2px solid var(--gold);
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 4px 5px 5px rgba(0,0,0,0.2);
}

.ServiceLink-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(60%);
    z-index: -1;
    transition: filter .2s;
}

.ServiceLink:hover .ServiceLink-image {
    filter: brightness(100%);
}

.ServiceLink-text {
    color: var(--gold);
    font-weight: 600;
    font-size: 1.5vw;
    text-align: center;
    left: 0px;
    right: 0px;
    bottom: 12px;
    position: absolute;
}

@media only screen and (max-width: 767px) {
    .ServiceLink {
        border-width: 1px;
        border-radius: 5px;
    }


    .ServiceLink-text {
        font-size: 15px;
        font-weight: 500;
    }

    .ServiceLink-image {
        filter: brightness(60%);
    }

}