.DataProtection {
    width: 75%;
    margin: 0 auto;
    text-align: justify;
    color: var(--white);
}

.DataProtection h2 {
    color: var(--gold);
    font-size: 21px;
    font-weight: 600;
    margin: 35px 0px -5px;
}

.DataProtection h3 {
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
}

.DataProtection ul {
    padding-left: 15px;
}

.DataProtection ul li {
    margin: 5px 0;
    list-style: none;
}

.DataProtection ul li::before {
    color: var(--gold);
    font-weight: bold;
    content: ">  "
}

.DataProtection a {
    font-weight: 600;
    color: var(--gold);
    overflow-wrap: break-word;
    word-break: break-all;
}

.DataProtection-concerningFirm {
    margin-left: 25px;
}