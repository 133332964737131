.Home {
  width: 100%;
  min-height: calc(100vh - 148px);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Home-background-image {
  z-index: -2;
  position: absolute;
  left: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
  top: 0px;
  bottom: 0px;
  object-fit: cover;
  object-position: left 10%;
  background-color: black;
  filter: brightness(75%);
}

.Home-banner {
  display: flex;
  flex-direction: column;
  color: var(--white);
  position: relative;
  padding: 25px;
  text-align: center;
}

.Home-banner-background {
  box-shadow: 0px 4px 5px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  opacity: 0.95;
  z-index: -1;
  background-color: var(--black);
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
}

.Home-slogan {
  border-bottom: 4px solid var(--gold);
  text-align: center;
  align-self: center;
  font-size: 2vw;
  margin-bottom: 25px;
  padding-bottom: 12.5px;
}

.Home-sub-slogan {
  font-size: 1vw;
}

.Home-lower-section {
  flex: 0;
  margin: 0 auto;
}

.Home-links {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  margin-top: 25px;
  gap: 25px;
}

@media screen and (max-width: 767px),
  (orientation: landscape) and (max-height: 400px) {
  .Home {
    height: 80vh;
  }

  .Home-banner-background {
    display: none;
  }

  .Home-banner {
    width: 80%;
  }

  .Home-slogan {
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 25px;
    border-bottom: 5px solid var(--gold);
  }

  .Home-background-image {
    filter: brightness(40%);
    object-position: 45% 35%;
  }

  .Home-sub-slogan {
    color: var(--white);
    font-size: 14px !important;
    padding: 0 25px;
    margin: 25px auto;
    text-align: center;
    min-height: 50px;
    display: block;
  }

  .Home-links {
    padding: 0 25px;
    margin-bottom: 35px;
    display: flex;
  }
}

@media screen and (max-width: 1200px) {
  .Home-sub-slogan {
    font-size: 1.5vw;
  }
}
