.AGB {
    width: 75%;
    margin: 0px auto 25px;
}

.AGB-header {
    margin-bottom: 0px;
}

.AGB-subtext {
    margin-top: 0px;
    color: var(--white);
    opacity: 0.4;
    font-weight: 500;
}

.AGB-sets h1 {
    color: var(--white);
    font-size: 21px;
    font-weight: 600;
    margin: 35px 0px -5px;
}

.AGB-sets p {
    font-size: 18px;
    color: var(--white);
    text-align: justify;
}


@media only screen and (max-width: 767px) {
    .AGB {
        width: 85%;
    }

    .AGB-subtext {
        font-size: 16px;
    }

    .AGB-sets h1 {
        font-size: 14px;
        text-decoration: underline;
        font-weight: 500;
    }
    
    .AGB-sets p {
        font-size: 14px;
    }
}