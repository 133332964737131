.Service {
    margin: 0px auto 25px;
}

.Service-header {
    position: relative;
    height: 30vh;
    display: flex;
    align-items: center;
    padding-left: 12.5vw;
}

.Service-header-image {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: 20% 32%;
    z-index: -1;
}

.Service-header-text::after {
    content: " ";
    display: block;
    height: 10px;
    width: 60px;
    border-bottom: 5px var(--gold) solid;
}

.Service-contactFormular-information {
    color: var(--white);
    margin-bottom: 20px;
    margin-left: 25px;
    min-width: 327px;
}

.Service-contactFormular-information a {
    color: var(--gold);
    font-weight: 500;
}

.Service-contactFormular-information h1 {
    color: var(--white);
    font-weight: 300;
    font-size: 24px;
    margin-top: 7.5px;
}

.Service-content {
    width: 50%;
    margin: 25px auto 0px;
}

.Service-text {
    color: var(--white);
    font-size: larger;
    width: 100%;
    text-align: justify;
}

.Service-contactFormular-header {
    margin: 0px 0 25px;
}

.Service-contactFormular-container {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    padding-top: 50px;
}

@media only screen and (max-width: 767px) {
    .Service-header-image {
        filter: brightness(60%);
    }

    .Service-header-text::after {
        border-bottom-width: 2.5px;
    }
    
    .Service-text {
        font-size: 14px;
        text-align: left;
    }
    
    .Service-content {
        width: 85%;   
    }

    .Service-contactFormular {
        width: 100%;
    }
}

@media only screen and (max-width: 1420px) {
    .Service-content {
        width: 65%;   
    }
}

@media only screen and (max-width: 1100px) {
    .Service-contactFormular {
        flex: 1;
    }
    .Service-contactFormular-information {
        margin-left: 0px;
    }
}