.ButtonLink {
    border: 2px solid var(--gold);
    color: var(--gold);
    padding: 10px;
    border-radius: 5px;
    font-weight: 500;
    box-shadow: 0px 4px 5px 5px rgba(0,0,0,0.25);
    transition: background-color 0.3s, border-radius 0.3s, color 0.3s;
}

.ButtonLink:hover {
    border-radius: 25px;
    background-color: var(--gold);
    color: var(--white);
}

@media screen and (max-width: 767px) {
    .ButtonLink {
        border-width: 1.5px;
        font-size: 14px;
    }
}

.ButtonLink-primary {
    background-color: var(--gold);
    color: var(--white);
}