.ContactFormular {
    display: grid;
    gap: 25px;
    grid-template-columns: 1fr 1fr;
}

.ContactFormular-spaned-item {
    grid-column-end: span 2;
}

.ContactFormular-agb-question {
    display: flex;
    align-items: center;
}

.ContactFormular-acceptsAGB-label {
    margin-left: 15px;
}

.ContactFormular-acceptsAGB-label, .ContactFormular-acceptsAGB-label a {
    color: var(--gold);
}

.ContactFormular-button {
    display: inline-block;
    padding: 10px;
    font-family: inherit;
    font-size: inherit;
    background-color: var(--gold);
    border-radius: 5px;
    color: var(--white);
    font-weight: 500;
    border: 2px solid var(--gold);
    box-shadow: 0px 4px 5px 5px rgb(0 0 0 / 25%);
    cursor: pointer;
    transition: opacity 0.3s;
}

.ContactFormular-button:disabled {
    opacity: 0.5;
}

@media only screen and (max-width: 767px) {
    .ContactFormular {
        display: flex;
        flex-direction: column;
    }

    .ContactFormular-acceptsAGB-label {
    }

}

/*
    InputField
*/

.InputField-container {
    font-family: inherit;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    resize: none;
}


.InputField {
    font-family: inherit;
    resize: none;
    background: #0E0E0E;
    box-shadow: inset 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border-width: 0px;
    padding: 15px 0px 15px 10px;
    color: var(--white);
    outline: none;
    font-size: 14px;
    box-sizing: border-box;
    width: 100%;
}

.InputField-redAsterix {
    position: absolute;
    top: 5px;
    right: 10px;
    text-align: right;
    color: red;
    pointer-events: none;
}

.InputField-multiline {
    min-height: 250px;
}

/*
    Checkbox
*/

.Checkbox {
    border: 3px solid var(--gold);
    border-radius: 100%;
    display: block;
    height: 15px;
    width: 15px;
    cursor: pointer;
    transition: background 0.3s;
    position: relative;
}

.Checkbox-checked {
    background-color: var(--gold);
}

@media only screen and (max-width: 767px) {
    .Checkbox {
        height: 25px;
        width: 25px;
    }
}