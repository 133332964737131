.AboutUs {
    width: 50%;
    margin: 0px auto 24px;
}

.AboutUs-text {
    color: var(--white);
    text-align: justify;
    font-size: 18px;
}

.AboutUs-image {
    width: 100%;
    margin: 0 auto;
}

@media only screen and (max-width: 767px) {
    .AboutUs {
        width: 85%;
    }

    .AboutUs-text {
        font-size: 14px;
    }
}