.Contact {
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin: 25px auto;
}

.Contact-contact {
    width: 60%;
}

.Contact-seperator {
    width: 5px;
    background-color: var(--gold);
    margin: 0px 25px;
}

.Contact-impressum-paragraph {
    color: var(--white);
    font-size: large;
}

.Contact-impressum-paragraph a {
    color: var(--gold);
    font-weight: 500;
}

@media only screen and (max-width: 1200px) {
    .Contact {
        width: 85%;
        margin: 25px auto;
        flex-direction: column-reverse;
    }

    .Contact-impressum-paragraph {
        font-size: 14px;
    }

    .Contact-contact {
        width: 100%;
    }
}