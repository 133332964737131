.Footer {
    color: var(--white);
    display: flex;
    justify-content: space-between;
    padding: 10px 25px;
    align-items: center;
    border-top: 1px solid var(--gray);
}

.Footer * {
    flex: 1;
}

.Footer-social-links {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Footer-social-link {
    display: inline-block;
    padding: 0 5px;
    flex: 0;
    height: 35px;
}

.Footer-social-link-image {
    height: 35px;
    max-width: 35px;
    cursor: pointer;
}

.Footer-link {
    color: var(--gold);
    cursor: pointer;
}

.Footer-designedBy {
    text-align: right;
}

.Footer-links-xs {
    display: grid;
    gap: 1px;
    grid-template-columns: 1fr 1fr;
    background-color: var(--gray);
    border-bottom: 1px solid var(--gray);
}

.Footer-link-xs {
    background-color: var(--black);
    height: 50px;
    justify-content: center;
    display: flex;
    align-items: center;
    color: var(--white);
}

.Footer-link-xs-stretch {
    grid-column: 1 / span 2;
}

.Footer-copyright-xs {
    color: gray;
    text-align: center;
    font-size: inherit;
    padding: 0 15px;
    margin-bottom: 25px;
}

.Footer-designedBy-xs {
    padding-left: 15px;
    margin-bottom: 5px;
}

@media only screen and (max-width: 767px) {
    .Footer {
        flex-direction: column;
        padding: 0px;
        align-items: stretch;
    }

    .Footer-social-link {
        padding: 0 15px;
        margin: 25px 0px;
    }
}