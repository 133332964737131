.NavigationBar {
    background-color: var(--black);
    display: flex;
    justify-content: space-between;
    height: 80px;
    padding: 5px 0px 5px 25px;
    box-sizing: content-box;
    position: sticky;
    left: 0px;
    right: 0px;
    box-shadow: 0px 4px 5px 5px rgba(0,0,0,0.5);
    z-index: 1;
    top: 0px;
}

.NavigationBar-logo {
    display: flex;
}

.NavigationBar-logo-image {
    height: 80%;
    align-self: center;
}

.NavigationBar-logo-text, .NavigationBar-logo-text-xs {
    text-transform: uppercase;
    font-family: 'Arvo', sans-serif;
    color: var(--gold);
    text-align: center;
    padding-left: 35px;
    font-size: larger;
    align-self: center;
}

.NavigationBar-links {
    display: flex;
}

.NavigationBar-link {
    color: var(--white);
    font-size: 1.1em;
    width: 140px;
    height: 100%;
    text-align: center;
    cursor: pointer;
    transition: border-bottom 0.1s;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    box-sizing: content-box;
}

.NavigationBar-open-navbar {
    border-top: 1px solid var(--gray);
    border-bottom: 1px solid var(--gray);
    position: absolute;
    top: 100%;
    left: 0px;
    width: 100vw;
    background-color: var(--gray);
    border-collapse: collapse;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1px 0px;
    box-shadow: 0px 4px 5px 5px rgba(0,0,0,0.5);
    transition: max-height 0.5s ease-in-out, border 0.1s;
    overflow: hidden;
    max-height: 100vh;
}

.NavigationBar-open-navbar-closed {
    max-height: 0px !important;
    border: 0px solid black;
}

@media only screen and (max-width: 768px) {
    .NavigationBar {
        padding: 5px 10px;
        height: 50px;
    }
    
    .NavigationBar-logo-text {
        font-size: 14px;
        padding-left: 0px;
    }

    .NavigationBar-logo-image {
        height: 100%;
        display: flex;
        align-items: center;
    }

    .NavigationBar-logo-image img {
        height: 70%;
    }

    .NavigationBar-link {
        display: flex;
        font-weight: 500;
        justify-content: flex-start;
        height: 65px;
        padding: 0px 20px;
        text-align: left;
        color: var(--white);
        background-color: var(--black);
        width: 100%;
    }

    .NavigationBar-link-active, .NavigationBar-link:hover {
        color: var(--gold);
    }
}

@media only screen and (min-width: 768px) {
    .NavigationBar {
        height: 60px;
        padding-left: 10px;
        padding: 5px 0px 5px 25px;
    }

    .NavigationBar-logo {
        justify-content: unset;
        flex: unset;
    }

    .NavigationBar-links {
        flex: unset;
        justify-content: flex-start;
    }

    .NavigationBar-logo-text {
        font-size: 16px;
        padding-left: 15px;
    }

    .NavigationBar-link {
        display: flex;
        width: 100px;
        font-size: 0.9em;
    }
    
    .NavigationBar-logo-image {
        height: 70%;
    }

    .NavigationBar-link-active, .NavigationBar-link:hover {
        border-bottom: 5px var(--gold) solid;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .NavigationBar-logo-image {
        height: 80%;
    }

    .NavigationBar {
        height: 80px;
        padding-left: 25px;
    }

    .NavigationBar-link {
        width: 140px;
        font-size: unset;
    }

    .NavigationBar-logo-text {
        font-size: larger;
        padding-left: 35px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}



/*
    Hamburger
*/

.Hamburger {
    flex-direction: column;
    width: 35px;
    height: 35px;
    align-self: center;
    justify-content: space-around;
    display: flex;
    position: relative;
}

.Hamburger-Line {
    background: var(--gold);
    height: 5px;
    border-radius: 5px;
}

.Hamburger-close-Line {
    background: var(--gold);
    height: 5px;
    border-radius: 5px;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50%;
}

.Hamburger-close-Line-1 {
    transform: rotate(45deg);
}

.Hamburger-close-Line-2 {
    transform: rotate(135deg);
}